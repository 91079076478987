<template>
  <Modal ref="modal" class="admin-menu-float mobile-s" @close="modalClose" :settings="{ opened: true }" :lock="$loading">
    <div class="admin-menu-float-title" slot="header">
      <p>{{ $locale["words"]["admin"] }}</p>
    </div>
    <div class="admin-menu __mobile">
      <AdminMenuOptions @filter="modalClose" />
    </div>
  </Modal>
</template>

<script>
export default {
  components: {
    AdminMenuOptions: () => import("../admin/AdminMenuOptions.vue"),
  },
  methods: {
    modalClose: function() {
      this.$store.commit("CreatorMenuMobile", false);
    },
  },
};
</script>
